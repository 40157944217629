import { Checkbox } from '@gerdau/hefesto';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { IShippingRequestRow, ISuggestionOption } from '@app/modules/shipping-request/shipping-request.interfaces';
import { getSuggestionValue } from '@app/modules/shipping-request/shipping-request.utils';
import { setSuggestions } from '@app/modules/shipping-request/store/shipping-request.store';

interface ShippingRequestCheckBoxFieldProps {
  item: IShippingRequestRow;
}

export const ShippingRequestSuggestionCheckBoxField: React.FC<ShippingRequestCheckBoxFieldProps> = ({ item }) => {
  const { suggestionOptions } = useSelector((state: RootState) => state.shippingRequest);
  const dispatch = useDispatch();

  const handleSuggestion = (currentItem: ISuggestionOption) => {
    const updatedSuggestions: ISuggestionOption[] = [];
    const currentItemIndex = suggestionOptions.findIndex(option => option.item.index === item.index);

    suggestionOptions.forEach((option, index) => {
      if (index < currentItemIndex) {
        const itemAux = {
          ...option.item,
          requestedShippingQuantity: getSuggestionValue(option.item.stockQuantity, option.item.shipmentQuantity),
        };
        updatedSuggestions.push({ item: itemAux, enabled: false, selected: true });
      } else if (currentItem && currentItem.item.index === index) {
        updatedSuggestions.push({ item: option.item, enabled: true, selected: true });
      } else {
        updatedSuggestions.push({ item: option.item, enabled: false, selected: false });
      }
    });

    return updatedSuggestions;
  };

  const handleCheck = (checked: boolean) => {
    const currentItem = suggestionOptions.find(suggestion => suggestion.item.index === item.index);
    let updatedSuggestions: ISuggestionOption[] = [];

    if (currentItem) {
      if (!checked) {
        const currentItemIndex = suggestionOptions.findIndex(option => option.item.index === item.index);

        suggestionOptions.forEach((option, index) => {
          const previousItem = suggestionOptions[currentItemIndex - 1];

          if (index >= currentItemIndex) {
            const itemAux = { ...option.item, requestedShippingQuantity: null };
            updatedSuggestions.push({ item: itemAux, enabled: false, selected: false });
          } else if (previousItem && previousItem.item.index === index) {
            updatedSuggestions.push({ item: option.item, enabled: true, selected: true });
          } else {
            updatedSuggestions.push({ item: option.item, enabled: false, selected: true });
          }
        });
      } else {
        updatedSuggestions = handleSuggestion(currentItem);
      }

      dispatch(setSuggestions(updatedSuggestions));
    }
  };

  const handleDisabled = () => {
    let isDisabled = true;

    if (suggestionOptions.length > 1) {
      const currentItemIndex = suggestionOptions.findIndex(option => option.item.index === item.index);

      if (currentItemIndex === 0) {
        isDisabled = false;
      } else {
        const previousItemSelected = suggestionOptions[currentItemIndex - 1].selected;
        previousItemSelected ? (isDisabled = false) : (isDisabled = true);
      }
    }

    return isDisabled;
  };

  if (!item) {
    return null;
  }

  return (
    <Checkbox
      name={`shipping-request-suggestion-${item.index}`}
      checked={suggestionOptions?.find(suggestion => suggestion?.item?.index === item?.index)?.selected}
      onChange={e => {
        handleCheck(e.target.checked);
      }}
      disabled={handleDisabled()}
    />
  );
};
