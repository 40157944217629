import { MaterialType, SearchByMaterial } from '../../order-input.constants';
import { IOrderInputState } from '../../order-input.interfaces';
import { postFiles } from './post-files.thunk';
import { validateMaterials } from './validate-materials.thunk';

export const postOrderAndValidateMaterial = () => async (dispatch, getState) => {
  const { files, searchBy }: IOrderInputState = getState().orderInput;
  const typeMaterialNameValue =
    searchBy === SearchByMaterial.CUSTOMER_MATERIAL_CODE ? MaterialType.CodProdClient : MaterialType.CodMatGerdau;

  if (files) {
    await dispatch(postFiles({ file: files, typeMaterialName: typeMaterialNameValue }));
    await dispatch(validateMaterials());
  }

  return;
};
