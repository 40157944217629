import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { ClientDetail, ProductDimensionOrder } from '@app/models/analysis.model';
import { MaterialCell, MaterialUnity } from '@app/modules/order-input/order-input.constants';
import { RadioField } from '@atomic/atm.radio';
import { FlexColumn } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { FormField } from '@atomic/obj.form';
import { handleProductAmountAndUnityChange, hasProductInputError } from '../../../../utils/utils';
import { UnityErrorWrapper } from './unity-field.style';

interface IUnityField {
  clientDetail?: ClientDetail;
  productDimensionOrder: ProductDimensionOrder;
  productDimensionOrders: ProductDimensionOrder[];
  setProductDimensionOrders: (dimensions: ProductDimensionOrder[]) => void;
}

export const UnityField: React.FC<IUnityField> = ({
  clientDetail,
  productDimensionOrder,
  productDimensionOrders,
  setProductDimensionOrders,
}) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);

  const handleValueChange = (value: string) => {
    const updatedProducts = productDimensionOrders.map(product => {
      if (product.tempId === productDimensionOrder.tempId) {
        product.unitMeasure = value;
      }

      product = handleProductAmountAndUnityChange(product, clientDetail[0]?.salesOrganization, userInfo.language);
      return product;
    });

    setProductDimensionOrders(updatedProducts);
  };

  return (
    <FlexColumn pl={Spacing.Medium}>
      <FormField
        value={productDimensionOrder.unitMeasure}
        onValueChange={value => {
          handleValueChange(value);
        }}
        name='unity'
      >
        <RadioField id={MaterialUnity.Ton}>{MaterialUnity.Ton}</RadioField>
        <RadioField id={MaterialUnity.Kg}>{MaterialUnity.Kg}</RadioField>
      </FormField>
      <UnityErrorWrapper>{hasProductInputError(productDimensionOrder, MaterialCell.Unity)}</UnityErrorWrapper>
    </FlexColumn>
  );
};
