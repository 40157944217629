export const TechnicalAnalysisComponentStrings = {
  pt: {
    attributeStepModal: {
      title: 'Seleção de atributos obrigatórios',
      ppap: 'Escolha o PPAP',
      priority: 'Escolha a prioridade da consulta',
      priorityLabel: {
        ME: {
          high: 'A (até 3 dias úteis)',
          medium: 'B (até 6 dias úteis)',
          low: 'C (até 9 dias úteis)',
        },
        MI: {
          high: 'A (até 5 dias corridos)',
          medium: 'B (até 10 dias corridos)',
          low: 'C (até 10 dias úteis)',
        },
      },
      ppapLabel: {
        no: 'Não',
        firstLevel: 'Nível 1',
        secondLevel: 'Nível 2',
        thirdLevel: 'Nível 3',
        fourthLevel: 'Nível 4',
        fifthLevel: 'Nível 5',
      },
      next: 'Prosseguir',
    },
    analysisApqpModal: {
      title: 'APQP',
      downloadButtonLabel: 'Baixar apqp.xls',
      apqp: {
        label: 'Essa análise precisa de APQP?',
        optionsLabel: {
          A: 'Sim, já está nos anexos',
          B: 'Sim, ainda não anexei',
          C: 'Não precisa de APQP',
        },
      },
      pe: {
        label: 'Essa análise precisa de Programa de Experiência (PE)',
        optionsLabel: {
          yes: 'Sim',
          no: 'Não',
        },
        observation: ' Indicar o número do PE no campo de comentários da CF.',
      },
      fmeas: {
        label: 'Os FMEAS foram avaliados?',
        optionsLabel: {
          yes: 'Sim',
          no: 'Não',
        },
      },
      change: {
        label: 'Houve necessidade de alteração?',
        optionsLabel: {
          yes: 'Sim',
          no: 'Não',
        },
      },
      newProductEpp: {
        label: 'É um produto novo de EPP?',
        optionsLabel: {
          yes: 'Sim',
          no: 'Não',
        },
        labelJustify: 'Descreva o motivo *',
      },
      next: 'Prosseguir',
    },
    analysisTypeModal: {
      title: 'Tipo de Cotação',
      label: 'Esta cotação é para:',
      mandatory: 'Este campo é obrigatório.',
      options: [
        { key: 'CP', label: 'Consulta Preço' },
        { key: 'AT', label: 'Análise Técnica' },
      ],
      titlePrioritySeller: 'Esta cotação é prioritária?',
      prioritySellerLabel: {
        ASeller: 'Sim',
        BSeller: 'Não',
      },
      next: 'Prosseguir',
    },
    analysisAssetsAdviceModal: {
      title: 'Atenção!',
      label: 'Há arquivos anexados a essa consulta que precisam ser verificados.',
      btnClose: 'Fechar',
    },
  },
  in: {
    attributeStepModal: {
      title: 'Selection of mandatory attributes',
      ppap: 'Choose the PPAP',
      priority: 'Choose quotation priority',
      priorityLabel: {
        ME: {
          high: 'A (up to 3 business days)',
          medium: 'B (up to 6 business days)',
          low: 'C (up to 9 business days)',
        },
        MI: {
          high: 'A (up to 5 days)',
          medium: 'B (up to 10 days)',
          low: 'C (up to 10 business days)',
        },
      },
      ppapLabel: {
        no: 'No',
        firstLevel: 'Level 1',
        secondLevel: 'Level 2',
        thirdLevel: 'Level 3',
        fourthLevel: 'Level 4',
        fifthLevel: 'Level 5',
      },
      next: 'Proceed',
    },
    analysisApqpModal: {
      title: 'APQP',
      downloadButtonLabel: 'Download apqp.xls',
      apqp: {
        label: 'Does this analysis need APQP?',
        optionsLabel: {
          A: 'Yes, its already in the attachments',
          B: 'Yes, I havent attached it yet',
          C: 'Dont need APQP',
        },
      },
      pe: {
        label: 'This analysis needs Experience Program (EP)',
        optionsLabel: {
          yes: 'Yes',
          no: 'No',
        },
        observation: ' Indicate the EP number in the CF comments field.',
      },
      fmeas: {
        label: 'Have FMEAS been evaluated?',
        optionsLabel: {
          yes: 'Yes',
          no: 'No',
        },
      },
      change: {
        label: 'Was there a need for change?',
        optionsLabel: {
          yes: 'Yes',
          no: 'No',
        },
      },
      newProductEpp: {
        label: 'Is it a new EPP product?',
        optionsLabel: {
          yes: 'Yes',
          no: 'No',
        },
        labelJustify: 'Describe the reason *',
      },
      next: 'Proceed',
    },
    analysisTypeModal: {
      title: 'Quotation Type',
      label: 'This quotation is for:',
      mandatory: 'This field is required.',
      options: [
        { key: 'CP', label: 'Price Inquiry' },
        { key: 'AT', label: 'Technical analysis' },
      ],
      titlePrioritySeller: 'Is this quote a priority?',
      prioritySellerLabel: {
        ASeller: 'Yes',
        BSeller: 'No',
      },
      next: 'Proceed',
    },
    analysisAssetsAdviceModal: {
      title: 'Attention!',
      label: 'There are files attached to this quotation that need to be verified..',
      btnClose: 'Close',
    },
  },
  es: {
    attributeStepModal: {
      title: 'Selección de atributos obligatorios',
      ppap: 'Elija el PPAP',
      priority: 'Elija la prioridad de la consulta',
      priorityLabel: {
        ME: {
          high: 'A (hasta 3 días hábiles)',
          medium: 'B (hasta 6 días hábiles)',
          low: 'C (hasta 9 días hábiles)',
        },
        MI: {
          high: 'A (hasta 5 días)',
          medium: 'B (hasta 10 días)',
          low: 'C (hasta 10 días hábiles)',
        },
      },
      ppapLabel: {
        no: 'No',
        firstLevel: 'Nivel 1',
        secondLevel: 'Nivel 2',
        thirdLevel: 'Nivel 3',
        fourthLevel: 'Nivel 4',
        fifthLevel: 'Nivel 5',
      },
      next: 'Proceder',
    },
    analysisApqpModal: {
      title: 'APQP',
      downloadButtonLabel: 'Descargar apqp.xls',
      apqp: {
        label: '¿Este análisis necesita APQP?',
        optionsLabel: {
          A: 'Sí, ya está en los archivos adjuntos',
          B: 'Sí, todavía no lo he adjuntado',
          C: 'No hay necesidad de APQP',
        },
      },
      pe: {
        label: 'Este análisis necesita Programa de Experiencia (PE)',
        optionsLabel: {
          yes: 'Sí',
          no: 'No',
        },
        observation: ' Indique el número de PE en el campo de comentarios de CF.',
      },
      fmeas: {
        label: '¿Se han evaluado FMEAS?',
        optionsLabel: {
          yes: 'Sí',
          no: 'No',
        },
      },
      change: {
        label: '¿Había necesidad de cambio?',
        optionsLabel: {
          yes: 'Sí',
          no: 'No',
        },
      },
      newProductEpp: {
        label: '¿Es un nuevo producto EPP?',
        optionsLabel: {
          yes: 'Sí',
          no: 'No',
        },
        labelJustify: 'Describe el motivo *',
      },
      next: 'Proceder',
    },
    analysisTypeModal: {
      title: 'Tipo de cotización',
      label: 'Esta cotización es para:',
      mandatory: 'Este campo es obligatorio.',
      options: [
        { key: 'CP', label: 'Consulta de precios' },
        { key: 'AT', label: 'Análisis técnico' },
      ],
      titlePrioritySeller: '¿Es esta cotización una prioridad?',
      prioritySellerLabel: {
        ASeller: 'Sí',
        BSeller: 'No',
      },
      next: 'Proceder',
    },
    analysisAssetsAdviceModal: {
      title: '¡Aviso!',
      label: 'Hay archivos adjuntos a esta consulta que deben verificarse.',
      btnClose: 'Cerrar',
    },
  },
};
