import { commonStatus, externalStatus, internalStatus } from '@app/modules/quote/quote.contants';
import { QuoteFilterLabelStrings } from '@app/modules/quote/quote.string';

export enum QuoteFilters {
  IsaNumber = 'id',
  Steel = 'steel',
  Specification = 'specification',
  Form = 'form',
  GaugeMin = 'gaugeMin',
  GaugeMax = 'gaugeMax',
  SideMin = 'sideMin',
  SideMax = 'sideMax',
  WidthMin = 'widthMin',
  WidthMax = 'widthMax',
  ThicknessMin = 'thicknessMin',
  ThicknessMax = 'thicknessMax',
  CornerRadius = 'cornerRadius',
  Client = 'client',
  User = 'email',
  Status = 'status',
  Pinda = 'pindamonhangaba',
  Charqueadas = 'charqueadas',
  Mogi = 'mogiDasCruzes',
  Plants = 'plants',
  Gerdau = 'resumoGerdau',
  RequisitionsPinda = 'requisitionsPinda',
  RequisitionsCharqueadas = 'requisitionsCharqueadas',
  RequisitionsMogi = 'requisitionsMogi',
  Flow = 'flow',
  Priority = 'priority',
  HasPrice = 'hasPrice',
  ClientVisible = 'clientVisible',
  Provider = 'provider',
}

export enum CornerRadiusTypes {
  None = 'NONE',
  AliveType = 'TIPO VIVO,',
  AType = 'TIPO A',
  SpecialType = 'TIPO ESPECIAL',
  BType = 'TIPO B',
  CType = 'TIPO C',
}

export enum FilterType {
  Text = 'text',
  Select = 'select',
  AutoComplete = 'autocomplete',
  MultiSelection = 'multiSelection',
  Client = 'client',
}

export enum FormTypesKeys {
  None = 'NONE',
  Round = 'REDONDO',
  Squared = 'QUADRADO',
  Hexagon = 'SEXTAVADO',
  Rectangular = 'RETANGULAR',
  Flat = 'CHATO',
}

export enum FormTypesLabel {
  None = '',
  Round = 'Redonda',
  Squared = 'Quadrada',
  Hexagon = 'Sextavada',
  Rectangular = 'Retangular',
  Flat = 'Chata',
}

export enum PlantTypesKeys {
  Pinda = 'pindamonhangaba',
  Charqueadas = 'charqueadas',
  Mogi = 'mogiDasCruzes',
  Gerdau = 'resumoGerdau',
}

export enum PlantTypesLabels {
  Pinda = 'Pindamonhangaba',
  Charqueadas = 'Charqueadas',
  Mogi = 'Mogi Das Cruzes',
  Gerdau = 'Gerdau - Resumo',
}

export enum RequisitionsTypesKeys {
  Required = 'required',
  Reviewed = 'reviewed',
  Selected = 'selected',
}

export const RequisitionsTypesLabels = {
  pt: { Required: 'Planta de abertura', Reviewed: 'Respondida por EPP/P&D', Selected: 'Enviada ao cliente' },
  in: { Required: 'Opening mill', Reviewed: 'Answered by EPP/P&D', Selected: 'Sent to customer' },
  es: { Required: 'Planta de apertura', Reviewed: 'Respondido por EPP/P&D', Selected: 'Enviado al cliente' },
};

export enum FlowTypesKeys {
  None = 'NONE',
  NewProduct = 'NEW_PRODUCT',
  GeneralSpecification = 'GENERAL_SPECIFICATION',
  SpecificSpecification = 'SPECIFIC_SPECIFICATION',
  RecurrentProduct = 'RECURRENT_PRODUCT',
  ClientManual = 'CLIENT_MANUAL',
}

export enum FlowTypesLabels {
  None = '',
  NewProduct = 'Novo Produto',
  GeneralSpecification = 'Revisão norma geral',
  SpecificSpecification = 'Revisão norma específica',
  RecurrentProduct = 'Produto Recorrente',
  ClientManual = 'Manual do Cliente / Fornecedor',
}

export enum PriorityTypes {
  A = 'A',
  B = 'B',
  C = 'C',
  GQ = 'GQ',
}

export enum PriorityTimes {
  A = 5,
  B = 10,
  C = 10,
  GQ = 30,
}

export enum PriorityTypesSeller {
  ASeller = 'A',
  BSeller = 'B',
}

export enum PriorityTimesSeller {
  ASeller = 5,
  BSeller = 10,
}

export interface DefaultParamsQuote {
  sort: string;
  price: string;
  startDate: string;
  endDate: string;
  skip: number;
  limit: number;
  myAnalysis?: boolean;
  myClients?: boolean;
}
export interface QuoteFilterData {
  id?: number;
  client?: string[];
  codeClients?: string[];
  steel?: string;
  specification?: string;
  form?: string;
  cornerRadius?: string;
  thicknessMin?: number;
  thicknessMax?: number;
  gaugeMin?: number;
  gaugeMax?: number;
  widthMin?: number;
  widthMax?: number;
  sideMin?: number;
  sideMax?: number;
  email?: string;
  status?: string[];
  pindamonhangaba?: string[];
  charqueadas?: string[];
  mogiDasCruzes?: string[];
  resumoGerdau?: string[];
  flow?: string[];
  priority?: string[];
  hasPrice?: boolean;
  clientVisible?: boolean;
  provider?: string;
  sort: string;
  price?: string;
  startDate: string;
  endDate: string;
  myAnalysis: boolean;
  myClients: boolean;
  skip: number;
  limit: number;
}

export interface QuoteFilterRange {
  min: number;
  max: number;
}

export const FormOptions = [
  { key: FormTypesKeys.None, label: FormTypesLabel.None },
  { key: FormTypesKeys.Round, label: FormTypesLabel.Round },
  { key: FormTypesKeys.Squared, label: FormTypesLabel.Squared },
  { key: FormTypesKeys.Hexagon, label: FormTypesLabel.Hexagon },
  { key: FormTypesKeys.Rectangular, label: FormTypesLabel.Rectangular },
  { key: FormTypesKeys.Flat, label: FormTypesLabel.Flat },
];

export const CornerRadiusOptions = [
  { key: CornerRadiusTypes.None, label: '' },
  { key: CornerRadiusTypes.AliveType, label: CornerRadiusTypes.AliveType },
  { key: CornerRadiusTypes.AType, label: CornerRadiusTypes.AType },
  { key: CornerRadiusTypes.SpecialType, label: CornerRadiusTypes.SpecialType },
  { key: CornerRadiusTypes.BType, label: CornerRadiusTypes.BType },
  { key: CornerRadiusTypes.CType, label: CornerRadiusTypes.CType },
];

export const RequisitionOptions = (language: string) => [
  { key: RequisitionsTypesKeys.Required, label: RequisitionsTypesLabels[language].Required },
  { key: RequisitionsTypesKeys.Reviewed, label: RequisitionsTypesLabels[language].Reviewed },
  { key: RequisitionsTypesKeys.Selected, label: RequisitionsTypesLabels[language].Selected },
];

export const FlowOptions = [
  { key: FlowTypesKeys.None, label: FlowTypesLabels.None },
  { key: FlowTypesKeys.NewProduct, label: FlowTypesLabels.NewProduct },
  { key: FlowTypesKeys.GeneralSpecification, label: FlowTypesLabels.GeneralSpecification },
  { key: FlowTypesKeys.SpecificSpecification, label: FlowTypesLabels.SpecificSpecification },
  { key: FlowTypesKeys.RecurrentProduct, label: FlowTypesLabels.RecurrentProduct },
  { key: FlowTypesKeys.ClientManual, label: FlowTypesLabels.ClientManual },
];

export const PriorityOptions = [
  { key: 'NONE', label: '' },
  { key: PriorityTypes.A, label: PriorityTypes.A },
  { key: PriorityTypes.B, label: PriorityTypes.B },
  { key: PriorityTypes.C, label: PriorityTypes.C },
];

export const PriceOptions = [
  { key: 'NONE', label: '' },
  { key: 'Sim', label: 'Sim' },
  { key: 'Não', label: 'Não' },
];

export const ClientVisibleOptions = [
  { key: 'NONE', label: '' },
  { key: 'Sim', label: 'Sim' },
  { key: 'Não', label: 'Não' },
];

export const ProviderOptions = [
  { key: 'NONE', label: '' },
  { key: 'CLIENT', label: 'Cliente' },
  { key: 'GERDAU', label: 'Gerdau' },
];

export interface QuoteFilter {
  order: number;
  key: string;
  title?: string;
  kind: string;
  small?: boolean;
  hidden?: (value?: any) => boolean;
  options?: any[];
  subSelection?: any[];
  subOptions?: any[];
}

export interface NewQuoteFilter {
  key: string;
  title?: string;
  kind: string;
  small?: boolean;
  hidden?: boolean;
  options?: any[];
  subSelection?: any[];
}

export const statusNone = { key: 'NONE', label: '' };

export const quoteFilters = (internalUser: boolean, language: string): QuoteFilter[] => {
  return [
    { order: 0, key: QuoteFilters.IsaNumber, title: QuoteFilterLabelStrings[language].id, kind: FilterType.Text },
    { order: 1, key: QuoteFilters.Steel, title: QuoteFilterLabelStrings[language].steel, kind: FilterType.Text },
    {
      order: 2,
      key: QuoteFilters.User,
      title: QuoteFilterLabelStrings[language].user,
      kind: FilterType.Text,
      hidden: () => !internalUser,
    },
    {
      order: 3,
      key: QuoteFilters.Specification,
      title: QuoteFilterLabelStrings[language].specification,
      kind: FilterType.Text,
    },
    {
      order: 4,
      key: QuoteFilters.Status,
      title: QuoteFilterLabelStrings[language].status,
      kind: FilterType.Select,
      options: internalUser
        ? [statusNone, ...internalStatus, ...commonStatus]
        : [statusNone, ...externalStatus, ...commonStatus],
    },
    {
      order: 5,
      key: QuoteFilters.Plants,
      title: QuoteFilterLabelStrings[language].plants,
      kind: FilterType.MultiSelection,
      options: [
        {
          key: QuoteFilters.Pinda,
          label: PlantTypesLabels.Pinda,
          kind: FilterType.MultiSelection,
          subSelection: RequisitionOptions,
        },
        {
          key: QuoteFilters.Charqueadas,
          label: PlantTypesLabels.Charqueadas,
          kind: FilterType.MultiSelection,
          subSelection: RequisitionOptions,
        },
        {
          key: QuoteFilters.Mogi,
          label: PlantTypesLabels.Mogi,
          kind: FilterType.MultiSelection,
          subSelection: RequisitionOptions,
        },
      ],
      hidden: () => !internalUser,
    },
    {
      order: 6,
      key: QuoteFilters.Form,
      title: QuoteFilterLabelStrings[language].form,
      kind: FilterType.Select,
      options: FormOptions,
      subOptions: [
        {
          key: QuoteFilters.GaugeMin,
          title: QuoteFilterLabelStrings[language].gaugeMin,
          kind: FilterType.Text,
          small: true,
          hidden: FormTypesKeys.Round,
        },
        {
          key: QuoteFilters.GaugeMax,
          title: QuoteFilterLabelStrings[language].gaugeMax,
          kind: FilterType.Text,
          small: true,
          hidden: (value: string) => value !== FormTypesKeys.Round,
        },
        {
          key: QuoteFilters.SideMin,
          title: QuoteFilterLabelStrings[language].sideMin,
          kind: FilterType.Text,
          small: true,
          hidden: (value: string) => value !== FormTypesKeys.Squared && value !== FormTypesKeys.Hexagon,
        },
        {
          key: QuoteFilters.SideMax,
          title: QuoteFilterLabelStrings[language].sideMax,
          kind: FilterType.Text,
          small: true,
          hidden: (value: string) => value !== FormTypesKeys.Squared && value !== FormTypesKeys.Hexagon,
        },
        {
          key: QuoteFilters.WidthMin,
          title: QuoteFilterLabelStrings[language].widthMin,
          kind: FilterType.Text,
          small: true,
          hidden: (value: string) => value !== FormTypesKeys.Rectangular && value !== FormTypesKeys.Flat,
        },
        {
          key: QuoteFilters.WidthMax,
          title: QuoteFilterLabelStrings[language].widthMax,
          kind: FilterType.Text,
          small: true,
          hidden: (value: string) => value !== FormTypesKeys.Rectangular && value !== FormTypesKeys.Flat,
        },
        {
          key: QuoteFilters.ThicknessMin,
          title: QuoteFilterLabelStrings[language].thicknessMin,
          kind: FilterType.Text,
          small: true,
          hidden: (value: string) => value !== FormTypesKeys.Rectangular && value !== FormTypesKeys.Flat,
        },
        {
          key: QuoteFilters.ThicknessMax,
          title: QuoteFilterLabelStrings[language].thicknessMax,
          kind: FilterType.Text,
          small: true,
          hidden: (value: string) => value !== FormTypesKeys.Rectangular && value !== FormTypesKeys.Flat,
        },
        {
          key: QuoteFilters.CornerRadius,
          title: QuoteFilterLabelStrings[language].cornerRadius,
          kind: FilterType.Select,
          options: CornerRadiusOptions,
          hidden: (value: string) => value !== FormTypesKeys.Flat,
        },
      ],
    },
    {
      order: 7,
      key: QuoteFilters.Priority,
      title: QuoteFilterLabelStrings[language].priority,
      kind: FilterType.Select,
      options: PriorityOptions,
    },
    {
      order: 8,
      key: QuoteFilters.Flow,
      title: QuoteFilterLabelStrings[language].flow,
      kind: FilterType.Select,
      options: FlowOptions,
    },
    {
      order: 9,
      key: QuoteFilters.HasPrice,
      title: QuoteFilterLabelStrings[language].hasPrice,
      kind: FilterType.Select,
      options: PriceOptions,
    },
    {
      order: 10,
      key: QuoteFilters.ClientVisible,
      title: QuoteFilterLabelStrings[language].clientVisible,
      kind: FilterType.Select,
      options: ClientVisibleOptions,
    },
    {
      order: 11,
      key: QuoteFilters.Provider,
      title: QuoteFilterLabelStrings[language].provider,
      kind: FilterType.Select,
      options: ProviderOptions,
    },
  ];
};
