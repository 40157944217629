import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { RootState } from '@app/core/redux/store';
import { npsOpenedSelector } from '@app/providers/navigation/navigation.selectors';
import { setOpenNpsModal } from '@app/providers/navigation/navigation.store';
import { FlexColumn } from '@atomic/obj.box';
import { Modal } from '@atomic/obj.modal';
import { NPS_MODAL_CUSTOM_WIDTH } from './nps-modal.constants';
import { NpsModalBody } from './partials/nps-modal-body/nps-modal-body';
import { NpsModalFooter } from './partials/nps-modal-footer/nps-modal-footer';
import { NpsModalHeader } from './partials/nps-modal-header/nps-modal-header';

export const NpsModal: React.FC = () => {
  const { hasNPS } = useSelector((state: RootState) => state.nps);
  const npsOpened = useSelector(npsOpenedSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (hasNPS) {
      dispatch(setOpenNpsModal(true));
    }
  }, [hasNPS]);

  useEffect(() => {
    LogAnalytics.pageView('nps');
  }, []);

  return (
    <Modal customWidth={NPS_MODAL_CUSTOM_WIDTH} opened={hasNPS && npsOpened}>
      <FlexColumn>
        <NpsModalHeader />
        <NpsModalBody />
        <NpsModalFooter />
      </FlexColumn>
    </Modal>
  );
};
