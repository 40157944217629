import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import {
  IShippingRequestRow,
  ISuggestionOption,
  ISuggestionOptionHistory,
} from '@app/modules/shipping-request/shipping-request.interfaces';
import { IShippingRequestStrings, Strings } from '@app/modules/shipping-request/shipping-request.string';
import { suggestionByPlantAndMaterialSelector } from '@app/modules/shipping-request/store/shipping-request.selectors';
import { setSuggestionHistory, setSuggestions } from '@app/modules/shipping-request/store/shipping-request.store';
import { Body } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { VSeparator } from '@atomic/obj.grid';
import { ShippingRequestSelectedItemTable } from '../../../shipping-request-selected-item-table/shipping-request-selected-item-table.component';
import { ShippingRequestSuggestionTable } from '../../../shipping-request-suggestion-table/shipping-request-suggestion-table.component';
import { ShippingRequestSuggestionModalBodyWrapper } from './shipping-request-suggestion-modal-body.style';

export const ShippingRequestSuggestionModalBody: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { openShippingRequestSuggestionModalMaterial, suggestionOptions } = useSelector(
    (state: RootState) => state.shippingRequest,
  );
  const suggestionsByPlantAndMaterial: IShippingRequestRow[] = useSelector((state: RootState) =>
    suggestionByPlantAndMaterialSelector(
      state,
      openShippingRequestSuggestionModalMaterial.plant,
      openShippingRequestSuggestionModalMaterial.bp,
      openShippingRequestSuggestionModalMaterial.material,
    ),
  );
  const strings: IShippingRequestStrings = Strings[userInfo.language];
  const dispatch = useDispatch<AppThunkDispatch>();

  useEffect(() => {
    if (suggestionsByPlantAndMaterial && suggestionOptions?.length === 0) {
      const options: ISuggestionOption[] = [];
      const optionsHistory: ISuggestionOptionHistory[] = [];

      suggestionsByPlantAndMaterial.forEach(option => {
        options.push({ item: option, selected: false, enabled: false, hasQtyError: false });
        optionsHistory.push({ item: option, enabled: false });
      });

      dispatch(setSuggestions(options));
      dispatch(setSuggestionHistory(optionsHistory));
    }
  }, [suggestionsByPlantAndMaterial]);

  return (
    <ShippingRequestSuggestionModalBodyWrapper>
      <FlexColumn>
        <Body bold={700}>{strings.suggestionModal.requestLabel}</Body>
        <VSeparator />
        <ShippingRequestSelectedItemTable />
        <VSeparator small lineVisible />
        <VSeparator />
        <Body bold={700}>{strings.suggestionModal.suggestionLabel}</Body>
        <VSeparator />
        <ShippingRequestSuggestionTable options={suggestionsByPlantAndMaterial} />
        <FlexRow pt>
          <Body bold={700}>{strings.suggestionModal.suggestionWarningLabel}</Body>
          <Body ml={Spacing.XSmall}>{strings.suggestionModal.suggestionWarningDescription}</Body>
        </FlexRow>
      </FlexColumn>
    </ShippingRequestSuggestionModalBodyWrapper>
  );
};
