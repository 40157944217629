import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Badge } from '@atomic/atm.badge';
import { Body, H3 } from '@atomic/atm.typography';
import { Table, TD, TR } from '@atomic/mol.table';
import { Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { VSeparator } from '@atomic/obj.grid';
import { QuoteStrings } from '../../quote.string';
import { TechnicalAnalysisStrings } from '../technical-analysis.string';
import { DescriptiveAnalysisTableStyled, DescriptiveAnalysisTextStyled } from './descriptiveAnalysis.component.style';

interface DescriptiveAnalysisProps {
  attrStatus: string;
  data: any;
}

export const DescriptiveAnalysisGeneral: React.FC<DescriptiveAnalysisProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const statusBadge = QuoteStrings[userInfo.language].constants.statusAttributes;
  const strings = TechnicalAnalysisStrings[userInfo.language].attributes.attributeCell.descriptiveAnalysis;

  return (
    props.data &&
    props.attrStatus !== 'accepted' && (
      <Hbox.Item>
        <H3>{strings.title}</H3>
        <VSeparator small />

        <DescriptiveAnalysisTableStyled>
          <Table collapse>
            <TR bordered sticky>
              <TD color={Color.White}>
                <Body>
                  <DescriptiveAnalysisTextStyled>
                    <Badge color='warning'>{statusBadge.derogate}</Badge>
                    {props?.data?.derogate?.reason}. <strong>{strings.suggestion}</strong>{' '}
                    {` ${props?.data?.derogate?.suggestion.length > 0 ? props?.data?.derogate?.suggestion.join() : ''}`}
                  </DescriptiveAnalysisTextStyled>
                </Body>
              </TD>
            </TR>
          </Table>
        </DescriptiveAnalysisTableStyled>

        <VSeparator />
      </Hbox.Item>
    )
  );
};
