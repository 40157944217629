import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TEST_ID_BACK_MODAL, TEST_ID_CLOSE_MODAL } from '@app/constants';
import { LogAnalytics } from '@app/core/analytics';
import { useStore } from '@app/core/global-store.service';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { EventTypes } from '@app/models/analytics.model';
import { CSATModule } from '@app/modules/admin-settings/admin-settings.modules';
import { UserStore, useUserStore } from '@app/providers';
import { postAnswerCsatUser } from '@app/providers/csat/csat-post.thunk';
import { setOpenNpsModal } from '@app/providers/navigation/navigation.store';
import { Button } from '@atomic/atm.button';
import { CheckboxField } from '@atomic/atm.checkbox';
import { FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { NpsPhase, NPS_CANCELED, NPS_VIEWED, TOP_ACCOUNTS_CORPORATE_GROUPS } from '../../nps-modal.constants';
import { InpsModalStrings, Strings } from '../../nps.string';
import { setDontSeeAgain, setNote, setPhase, submitNps } from '../../store/nps-modal.store';
import { putNPS } from '../../store/thunk/nps-put.thunk';
import { NpsModalAdvanceButton } from './nps-modal-advance-button';
import { NpsModalFooterWrapper } from './nps-modal-footer.style';

export const NpsModalFooter: React.FC = () => {
  const { user } = useStore<UserStore>(useUserStore);
  const strings: InpsModalStrings = Strings[user?.language];
  const dispatch = useDispatch<AppThunkDispatch>();
  const { userInfo, ssoAccess } = useSelector((state: RootState) => state.auth);
  const { dontSeeAgain, phase, note, message } = useSelector((state: RootState) => state.nps);
  const { clientBpsSelected } = useSelector((state: RootState) => state.customerPersist);
  const { loadingPost } = useSelector((state: RootState) => state.csat);

  const handleSuccess = () => {
    dispatch(setOpenNpsModal(false));
  };

  const npsProgress = phase !== NpsPhase.Success && phase !== NpsPhase.Message;

  const handleClose = () => {
    const corporateGroup = clientBpsSelected[0]?.corpGroup;
    const salesOrg = clientBpsSelected[0]?.salesOrg;
    const corpGroup = clientBpsSelected[0]?.corpGroup;
    const isTopAccount = TOP_ACCOUNTS_CORPORATE_GROUPS.includes(corporateGroup);
    const optaramPorNaoResponder = dontSeeAgain;

    if (dontSeeAgain && npsProgress) {
      dispatch(
        putNPS({
          _id: userInfo?._id,
          isTopAccount,
          salesOrg,
          corpGroup,
          optaramPorNaoResponder,
          note: note,
          message: message,
          answer: NPS_CANCELED,
          ssoAccess: ssoAccess,
          language: user?.language,
        }),
      );

      dispatch(postAnswerCsatUser({ functionality: CSATModule.nps }));
      LogAnalytics.logEvent(EventTypes.Click, { kind: 'nps-not-reply' });
      dispatch(submitNps({ isTopAccount, salesOrg, corpGroup }));
    } else if (!npsProgress && phase !== NpsPhase.Success) {
      dispatch(postAnswerCsatUser({ functionality: CSATModule.nps, handleSuccess }));
    } else {
      if (!NpsPhase.Success) {
        dispatch(
          putNPS({
            _id: userInfo?._id,
            isTopAccount,
            salesOrg,
            corpGroup,
            optaramPorNaoResponder,
            note: note,
            message: message,
            answer: NPS_VIEWED,
            ssoAccess: ssoAccess,
            language: user?.language,
          }),
        );
      }

      dispatch(setOpenNpsModal(false));
      LogAnalytics.logEvent(EventTypes.Click, { kind: 'nps-reply-later' });
    }
  };

  const handleClickBack = () => {
    dispatch(setPhase(NpsPhase.Grade));
  };

  const handleCheckDontSeeAgain = () => {
    dispatch(setDontSeeAgain(!dontSeeAgain));
    dispatch(setNote(null));
  };

  return (
    <NpsModalFooterWrapper>
      {npsProgress && (
        <FlexRow mr>
          <CheckboxField
            paddingLeft={Spacing.Large}
            checked={dontSeeAgain}
            onValueChange={() => handleCheckDontSeeAgain()}
            id='dontSeeAgain'
          >
            {strings.dontSeeAgainMessage}
          </CheckboxField>
        </FlexRow>
      )}
      {phase !== NpsPhase.Message && (
        <Button
          testid={`${TEST_ID_CLOSE_MODAL}-nps`}
          kind='secondary'
          loading={loadingPost}
          onClick={() => handleClose()}
        >
          {strings?.closeButton}
        </Button>
      )}
      {phase === NpsPhase.Message && (
        <Button
          testid={`${TEST_ID_BACK_MODAL}-nps`}
          kind='secondary'
          loading={loadingPost}
          onClick={() => handleClickBack()}
        >
          {strings?.backButton}
        </Button>
      )}
      {phase !== NpsPhase.Success && <NpsModalAdvanceButton />}
    </NpsModalFooterWrapper>
  );
};
