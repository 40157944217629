import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { RootState } from '@app/core/redux/store';
import { InStockOrderItem } from '@app/models';
import { isEatonSelector } from '@app/modules/components/customer-search/store/customer-search.selectors';
import { ShippingRequestEvents } from '@app/modules/order/order.analytics';
import { PlantNames } from '@app/modules/shipping-request/shipping-request.constants';
import { IPlant, ISuggestionOptionHistory } from '@app/modules/shipping-request/shipping-request.interfaces';
import { IShippingRequestStrings, Strings } from '@app/modules/shipping-request/shipping-request.string';
import { getUpdatedPlant, handleNewTableRows } from '@app/modules/shipping-request/shipping-request.utils';
import {
  getRequestedTotalValue,
  getSuggestionsTotalValue,
} from '@app/modules/shipping-request/store/shipping-request.selectors';
import {
  setChaPlant,
  setMogPlant,
  setOpenShippingRequestSuggestionModalMaterial,
  setPinPlant,
  setSuggestionHistory,
  setTableRows,
} from '@app/modules/shipping-request/store/shipping-request.store';
import { formatQtyField } from '@app/utils/string-utils';
import { Button } from '@atomic/atm.button';
import { Body, DT } from '@atomic/atm.typography';
import { FlexColumn, FlexRow, Hbox } from '@atomic/obj.box';
import { ShippingRequestSuggestionModalTotalFooter } from '../../../shipping-request-suggestion-table/shipping-request-suggestion-table.style';

export const ShippingRequestSuggestionModalFooter: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { data } = useSelector((state: RootState) => state.orderTabStock);
  const { suggestionOptions, tableRows, openShippingRequestSuggestionModalMaterial, mog, pin, cha } = useSelector(
    (state: RootState) => state.shippingRequest,
  );
  const totalRequested: number = useSelector((state: RootState) =>
    getRequestedTotalValue(
      state,
      openShippingRequestSuggestionModalMaterial?.plant,
      openShippingRequestSuggestionModalMaterial.bp,
      openShippingRequestSuggestionModalMaterial?.material,
    ),
  );
  const isEaton = useSelector(isEatonSelector);
  const totalSuggested: number = useSelector((state: RootState) => getSuggestionsTotalValue(state, isEaton));
  const strings: IShippingRequestStrings = Strings[userInfo.language];
  const dispatch = useDispatch();

  const handleConfirm = () => {
    LogAnalytics.click({
      tipo: ShippingRequestEvents.SuggestionModalConfirm,
    });

    const selectedInStockOrderItem: InStockOrderItem[] = [];

    const selectedSuggestions = suggestionOptions
      .filter(suggestionOpt => suggestionOpt.selected)
      .map(suggestion => suggestion.item);

    const updatedTableRows = handleNewTableRows(tableRows, suggestionOptions[0].item.material, selectedSuggestions);

    updatedTableRows.forEach(row => {
      data.find(item => {
        if (item.salesDocument === row.salesOrder) {
          selectedInStockOrderItem.push(item);
        }
      });
    });

    const currentPlantData: IPlant =
      openShippingRequestSuggestionModalMaterial.plant === PlantNames.MOGI
        ? mog
        : openShippingRequestSuggestionModalMaterial.plant === PlantNames.PINDA
        ? pin
        : cha;

    const updatedPlant = getUpdatedPlant(
      currentPlantData,
      openShippingRequestSuggestionModalMaterial.bp,
      openShippingRequestSuggestionModalMaterial.material,
      updatedTableRows,
    );

    dispatch(setTableRows({ selectedRows: selectedInStockOrderItem, isGKN: false, isEaton: isEaton }));

    switch (openShippingRequestSuggestionModalMaterial.plant) {
      case PlantNames.MOGI:
        dispatch(setMogPlant(updatedPlant));
        break;
      case PlantNames.PINDA:
        dispatch(setPinPlant(updatedPlant));
        break;
      case PlantNames.CHARQ:
        dispatch(setChaPlant(updatedPlant));
        break;
      default:
        break;
    }

    const optionsHistory: ISuggestionOptionHistory[] = [];

    suggestionOptions.forEach(option => {
      optionsHistory.push({ item: option.item, enabled: option.enabled });
    });

    dispatch(setSuggestionHistory(optionsHistory));
    dispatch(setOpenShippingRequestSuggestionModalMaterial(null));
  };

  const handleDisabled = () => {
    let isDisabled = true;
    const hasAnyQtyError = suggestionOptions.find(option => option.hasQtyError);
    const hasAnySelectedEatonWithoutValue = suggestionOptions.find(
      option => option.selected && !option.item.requestedShippingQuantity,
    );

    if (suggestionOptions.length === 1 || suggestionOptions[0]?.selected) {
      isDisabled = false;
    }

    if ((isEaton && hasAnySelectedEatonWithoutValue) || (isEaton && hasAnyQtyError)) {
      isDisabled = true;
    }

    return isDisabled;
  };

  return (
    <FlexRow pb pr pt>
      <ShippingRequestSuggestionModalTotalFooter>
        <FlexColumn>
          <Body bold='bold'>{strings.suggestionModal.totalRequestedQty}</Body>
          <DT>{`${formatQtyField(totalRequested)}t`}</DT>
        </FlexColumn>
        <Hbox.Separator small />
        <FlexColumn>
          <Body bold='bold'>{strings.suggestionModal.totalSelectedQty}</Body>
          <DT>{`${formatQtyField(totalSuggested)}t`}</DT>
        </FlexColumn>
      </ShippingRequestSuggestionModalTotalFooter>
      <FlexRow hAlign='flex-end' fullWidth>
        <Button disabled={handleDisabled()} onClick={() => handleConfirm()}>
          <FlexRow>{strings.suggestionModal.confirmButton}</FlexRow>
        </Button>
        <Button kind='secondary' onClick={() => dispatch(setOpenShippingRequestSuggestionModalMaterial(null))}>
          {strings.suggestionModal.cancelButton}
        </Button>
      </FlexRow>
    </FlexRow>
  );
};
