import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { MarketType } from '@app/providers';
import { Frame } from '@atomic/atm.frame';
import { Image } from '@atomic/atm.image';
import { H2 } from '@atomic/atm.typography';
import { Separator } from '@atomic/obj.box';
import { Col, Grid, Row } from '@atomic/obj.grid';
import { hasPDFAccessSelector } from '../auth/store/auth.selectores';
import { ConfigColumnsContext } from './components/config-columns-modal/config-columns-modal.context';
import { OrderInputWizard } from './components/order-input-wizard/order-input-wizard';
import { OrderInputPhaseEnum } from './order-input.constants';
import { OrderInputStrings } from './order-input.string';
import { OrderInputAmount } from './phases/order-input-amount/order-input-amount';
import { OrderInputMultiple } from './phases/order-input-multiple/order-input-multiple';
import { OrderInputOptions } from './phases/order-input-options/order-input-options';
import { OrderInputResume } from './phases/order-input-resume/order-input-resume';
import { OrderInputSelectItens } from './phases/order-input-select-itens/order-input-select-itens';
import { OrderInputSuccess } from './phases/order-input-success/order-input-success.component';
import { OrderInputUploadPdfEmail } from './phases/order-input-upload-pdf-email/order-input-upload-pdf-email.component';
import { OrderInputUploadSpreadsheet } from './phases/order-input-upload-spreasheet/order-input-upload-spreadsheet';
import { RESET_ORDER_INPUT_ACTION } from './store/order-input.reducers';

export const OrderInputPage: React.FC<{}> = () => {
  const { currentMarket, clientBpsSelected } = useSelector((state: RootState) => state.customerPersist);
  const hasPDFAccess = useSelector(hasPDFAccessSelector);
  const { phase } = useSelector((state: RootState) => state.orderInput);
  const [spreadSheetFile, setSpreadSheetFile] = useState<any>('');
  const Strings = OrderInputStrings.pt;
  let enabledOptions = [];
  const dispatch = useDispatch<AppThunkDispatch>();

  const baseOptions = [
    {
      phaseId: 'uploadSpreadsheet',
      icon: <Image.TableIcon />,
      activeIcon: <Image.TableIconActive />,
      title: Strings.options.uploadSpreadsheet.title,
      subtitle: Strings.options.uploadSpreadsheet.subtitle,
      active: true,
      templateDownload: true,
      isNew: false,
      iaTag: false,
    },
    {
      phaseId: 'itensSelection',
      icon: <Image.SelectionIcon />,
      activeIcon: <Image.SelectionIconActive />,
      title: Strings.options.itensSelection.title,
      subtitle: Strings.options.itensSelection.subtitle,
      active: true,
      isNew: false,
      iaTag: false,
    },
  ];

  if (currentMarket === MarketType.Internal) {
    if (hasPDFAccess) {
      baseOptions.push({
        phaseId: 'uploadPdfEmail',
        icon: <Image.SelectionIcon />,
        activeIcon: <Image.AttachmentIconActive />,
        title: Strings.options.uploadPdfEmail.title,
        subtitle: Strings.options.uploadPdfEmail.subtitle,
        active: true,
        isNew: true,
        iaTag: true,
      });
    }

    enabledOptions = baseOptions;
  }

  if (currentMarket === MarketType.External && hasPDFAccess) {
    enabledOptions = [
      {
        phaseId: 'uploadSpreadsheet',
        icon: <Image.TableIcon />,
        activeIcon: <Image.TableIconActive />,
        title: Strings.options.uploadSpreadsheet.title,
        subtitle: Strings.options.uploadSpreadsheet.subtitle,
        active: true,
        templateDownload: true,
        isNew: false,
        iaTag: false,
      },
      {
        phaseId: 'itensSelection',
        icon: <Image.SelectionIcon />,
        activeIcon: <Image.SelectionIconActive />,
        title: Strings.options.itensSelection.title,
        subtitle: Strings.options.itensSelection.subtitle,
        active: true,
        isNew: false,
        iaTag: false,
      },
      {
        phaseId: 'uploadPdfEmail',
        icon: <Image.SelectionIcon />,
        activeIcon: <Image.AttachmentIconActive />,
        title: Strings.options.uploadPdfEmail.title,
        subtitle: Strings.options.uploadPdfEmail.subtitle,
        active: true,
        isNew: true,
        iaTag: true,
      },
    ];
  }

  const phases = {
    [OrderInputPhaseEnum.Options]: <OrderInputOptions options={enabledOptions} />,
    [OrderInputPhaseEnum.UploadSpreadsheet]: <OrderInputUploadSpreadsheet />,
    [OrderInputPhaseEnum.UploadPdfEmail]: <OrderInputUploadPdfEmail />,
    [OrderInputPhaseEnum.ItensSelection]: <OrderInputSelectItens />,
    [OrderInputPhaseEnum.DataAndAmout]: <OrderInputAmount />,
    [OrderInputPhaseEnum.Multiple]: <OrderInputMultiple />,
    [OrderInputPhaseEnum.Resume]: <OrderInputResume />,
    [OrderInputPhaseEnum.Success]: <OrderInputSuccess />,
  };

  useEffect(() => {
    dispatch(RESET_ORDER_INPUT_ACTION);
  }, [clientBpsSelected]);

  return (
    <ConfigColumnsContext.Provider
      value={{
        spreadSheetFile,
        setSpreadSheetFile,
      }}
    >
      <Frame>
        <Grid fluid>
          <Row pb>
            <Separator />
            <Col lg={8}>
              <H2 data-testid='order-input-title'>{Strings.title}</H2>
            </Col>
            <Col lg={4}>
              {phase !== OrderInputPhaseEnum.Options && phase !== OrderInputPhaseEnum.Success && (
                <OrderInputWizard phase={phase} />
              )}
            </Col>
            <Separator />
          </Row>
        </Grid>
      </Frame>
      {phases[phase]}
    </ConfigColumnsContext.Provider>
  );
};
